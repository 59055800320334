@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@100;200;300;400;500;600;800;900&display=swap);
* {
  font-size: 15px;
}

body {
  font-family: 'Poppins', sans-serif;
}

.skeleton {
  min-width: 4rem;
  display: inline-block;
  border-radius: 1rem;
  background-color: rgb(255,255,255,0.3);
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.modal-animate {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.modal-animate.show {
  opacity: 1;
}
.modal-animate.hide {
  opacity: 0;
}

.d-none {
  display: none;
}

.py-custom-1 {
  padding-top: 2px;
  padding-bottom: 2px;
}

/* tampilan mobile */
@media (max-width: 768px) {
  .mobile-hidden {
    display: none;
  }

  .mobile-show {
    display: block;
  }

  .mobile-justify-center {
    display: flex;
    justify-content: center;
  }

  .mobile-w-100 {
    width: 100%;
  }

  .img-responsive{
    padding: 0 2.5rem;
  }

  .text-size-custom {
    font-size: x-large;
  }

  .aspect-instagram {
    aspect-ratio: auto;
  }
}

/* tampilan desktop */
@media (min-width: 768px) {
  .mobile-show {
    display: none;
  }

  .text-size-custom {
    font-size: large;
  }

  .aspect-instagram {
    aspect-ratio: 1/1;
  }
}

.berita-terkini {
  cursor: pointer;
}

.berita-terkini .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.berita-terkini .img {
  transition: 0.5s ease;
}
.berita-terkini:hover .img {
  transform: scale(1.1);
  transition: 0.5s ease;
}

.berita-terkini:hover .mask {
  background-color: rgba(0, 0, 0, 0.25);
}

.berita-terkini .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; /* Atur maksimum jumlah baris */
}

.row-span {
  height: 200px; /* Atur tinggi yang sesuai */
  background-color: lightblue; /* Atur warna latar belakang yang sesuai */
}

.custom-hue {
  -webkit-backdrop-filter: hue-rotate(20deg);
  backdrop-filter: hue-rotate(20deg);
}

.nice-background {
  background: linear-gradient(rgb(0, 0, 0, 0.6) 50%, rgb(0, 0, 0, 0.6) 50%), url(https://dev.alfaprima.id/assets/img/bg-awesome.jpg);
  background-position: center;
  background-size: cover;
}
.score-background {
  background: linear-gradient(rgb(15, 32, 124, 0.9) 50%, rgb(15, 32, 124, 0.9) 50%), url(https://dev.alfaprima.id/assets/img/city.svg);
  background-position: center;
  background-size: cover;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0,0, 0.3), inset 0 0 5rem rgba(0, 0,0, 0.25);
}
.alfa-background {
  background: linear-gradient(rgb(15, 32, 124, 0.95) 50%, rgb(15, 32, 124, 0.95) 50%), url(https://dev.alfaprima.id/assets/img/bg-alfa.jpg);
  background-position: center;
  background-size: cover;
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0,0, 0.3), inset 0 0 5rem rgba(0, 0,0, 0.25);
}

.bg-alfaprima {
  background: url(https://dev.alfaprima.id/assets/logo/AP-W.png);
  background-position: center;
  background-size: cover;
}

.text-sm {
  font-size: 13px;
}

.fst-alfaprima {
    font-family: 'Playfair Display', serif;
}

.collapse-nice {
  overflow: hidden;
  max-height: 0;
  /* transition: max-height 1s ease; */
}

.collapse-nice.show-nice {
  max-height: 100%;
}

.input-group {
  background-color: #ffffff !important;
  box-shadow: inset 0 0 3rem rgba(0, 0, 0, 0.1);
}

.form-check-input {
  background-color: #00000000;
}
.form-check-input:checked {
  background-color: #00df8d;
}

.form-control-login-pc {
  box-shadow: none;
  background-color: #00000000;
  /* transition: 0.1s ease-in-out; */
}
.form-control-login-pc:hover {
  box-shadow: none;
  background-color: #00000010;
}
.form-control-login-pc:focus {
  box-shadow: none;
  background-color: #00000010;
}
.form-control-login-pc::placeholder {
  font-weight: normal;
  color: #858796;
}

.form-control-nice {
  border: none;
  box-shadow: inset 0 0.5rem 0.5rem rgba(0, 0,0, 0.2);
  transition: 0.1s ease-in-out;
}
.form-control-nice:hover {
  border: none;
  box-shadow: inset 0 0.5rem 0.5rem rgba(0, 0,0, 0.2);
}
.form-control-nice:focus {
  border: none;
  box-shadow: inset 0 0.5rem 0.5rem rgba(0, 0,0, 0.2);
  padding-left: 1.5rem !important;
}
.form-control-nice::placeholder {
  color: #858796;
}

.form-control-custom {
  border: 1px solid #dddddd;
  box-shadow: none;
  transition: 0.1s ease-in-out;
}
.form-control-custom:hover {
  border: 1px solid #dddddd;
  box-shadow: none;
}
.form-control-custom:focus {
  border: 1px solid #dddddd;
  padding-left: 1.5rem;
  /* box-shadow: 0 0 0 2px rgba(3, 188, 225, 0.25); */
  box-shadow: none;
}
.form-control-custom::placeholder {
  color: #858796;
}

.form-select-custom {
  border: 1px solid #dddddd;
  box-shadow: none;
  transition: 0.1s ease-in-out;
}
.form-select-custom:hover {
  border: 1px solid #dddddd;
  box-shadow: none;
}
.form-select-custom:focus {
  border: 1px solid #dddddd;
  padding-left: 1.5rem;
  box-shadow: none;
}

.box-image {
  padding: 75px 0;
  border: 2px solid #6e707e !important;
  border-style: dashed !important;
  color: #6e707e;
  transition: 0.1s ease-in-out;
}
.box-image:hover {
  background-color: rgb(11, 89, 161, 0.25);
  color: #03bbe1;
  padding: 75px 0;
  cursor: pointer;
}

.custom-file-button input[type=file] {
  margin-left: -2px !important;
  box-shadow: inset 0 0.5rem 0.5rem rgba(0, 0,0, 0.2);
}

.custom-file-button input[type=file]::-webkit-file-upload-button {
  display: none;
}

.custom-file-button input[type=file]::file-selector-button {
  display: none;
}

.custom-file-button:hover label {
  background-color: #dde0e3;
  cursor: pointer;
}




.border-bottom-warning {
  border-bottom: 2.5px solid #ffb901 !important;
}
.border-bottom-primary {
  border-bottom: 2.5px solid #0f207c !important;
}
.border-bottom-primary-3 {
  border-bottom: 2.5px solid #087ab6 !important;
}



.btn-disable {
  padding-top: 3.5px;
  padding-bottom: 3.5px;
  font-size: 0.875rem;
  color: #00df8d !important;
  /* border: 1.5px solid #00df8d !important; */
  background-color: #0f207c !important;
}

.cursor-disable {
  cursor: not-allowed;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: none;
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: none;
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  box-shadow: none;
  border: none;
}
.accordion-button:hover {
  box-shadow: none;
  border: none;
}
.accordion-button:focus {
  box-shadow: none;
  border: none;
}
.accordion-button:active {
  box-shadow: none;
  border: none;
}

.text-justify {
  text-align: justify;
}

.table-danger {
  background-color: red;
}

.row-size {
  padding: 0px;
  margin: 0px -16px;
}

.box {
    background-color: #303030;
    width: 100%;
    height: 500px;
}

.shadow-in {
  box-shadow: inset 0 0 1rem rgba(0,0,0, 0.05)!important;
}
.shadow-profile {
  box-shadow: 0 0 2rem rgb(0, 0, 0, 0.5);
}
.shadow-nice {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0,0, 0.3);
}
.shadow-nice-success {
  box-shadow: 0 0rem 0.5rem #00df8d;
}
.shadow-nice-center {
  box-shadow: 0 0 5rem rgba(0, 0, 0, 1);
}
.shadow-xl {
    box-shadow: 0px 0px 15px rgb(0, 0, 0, 0.5);
}

.ipk-shadow {
  text-shadow: 0 0 3rem rgba(255, 255, 0, 0.75);
}
.text-shadow {
  text-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.3);
}

.offcanvas-title {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

.navbar-brand {
    font-family: 'Playfair Display', serif;
    font-weight: 400;
}

.nav-link {
    font-weight: 400;
}

.bg-none {
  background-color: rgba(0, 0, 0, 0) !important;
}
.bg-primary {
    background-color: #0f207c !important;
}
.bg-primary-2 {
    background-color: #0b59a1 !important;
}
.bg-primary-3 {
    background-color: #087ab6 !important;
}
.bg-primary-4 {
    background-color: #091349 !important;
}
.bg-primary-5 {
    background-color: #172eb4 !important;
}
.bg-primary-transparent {
  background: linear-gradient(0deg, #091349 40%, #09134900 100%) !important;
}
.bg-warning-transparent {
  background: linear-gradient(0deg, #9c7000 40%, #9c700000 100%) !important;
}
.bg-info {
    background-color: #404a4d !important;
}
.bg-warning {
    background-color: #ffb901 !important;
}
.bg-silver {
  background-color: rgb(200, 200, 200);
}



.border-silver {
  border-color: #36459b !important;
}
.border-dark-transparent {
  border-color: rgba(0, 0, 0, 0.25) !important;
}



.text-primary {
    color: #0f207c !important;
}
.text-info {
  color: #03bbe1 !important;
}
.text-warning {
    color: #ffb901 !important;
}
.text-warning-dark {
    color: #c99000 !important;
}
.text-warning-animation {
  color: #ffb901 !important;
    animation: warning-animation 2s infinite alternate ease-in-out;
}
@keyframes warning-animation {
    0% {
      transform: translateX(0rem);
    text-shadow: 0 0 1rem #ffb901;
    }
    100% {
      transform: translateX(1rem);
    text-shadow: 0 0 3rem #fffc43;
    }
}
.text-danger-light {
    color: #e02d1b !important;
}
.text-success-light {
    color: #00df8d !important;
}
.text-green-light {
  color: rgb(0, 255, 0);
}
.text-red-light {
  color: rgb(255, 0, 0);
}

.text-score {
  color: #fffc43 !important;
  text-shadow: 0 0 3rem rgb(255, 255, 255, 0.75);
  /* animation: text-score 2s infinite alternate ease-in-out; */
}
.text-score-a {
  color: #fffc43 !important;
  text-shadow: 0 0 1.5rem rgb(255, 255, 255, 0.75);
  /* animation: text-score 2s infinite alternate ease-in-out; */
}
.text-score-b {
  color: #75ffff !important;
  text-shadow: 0 0 1.5rem rgb(255, 255, 255, 0.5);
  /* animation: text-score 2s infinite alternate ease-in-out; */
}
.text-score-c {
  color: #dddddd !important;
}
.text-score-d {
  /* color: #e28720 !important; */
  color: #dddddd !important;
}



.border-primary {
    border-color: #0f207c !important;
}
.border-warning {
    border-color: #ffb901 !important;
}



.link-primary {
    color: #757575 !important;
}
.link-primary:hover {
    color: #0f207c !important;
}
.link-primary:focus {
    color: #0f207c !important;
}
.link-primary:active {
    color: #0f207c !important;
}



.navbar-toggler {
    box-shadow: none;
}
.navbar-toggler:hover {
    box-shadow: none;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-toggler:active {
    box-shadow: none;
}



.bg-gradient-primary {
    background-color: #0f207c;
    background-image: linear-gradient(180deg, #0f207c 10%, #091349 100%);
    background-size: cover;
}

.bg-gradient-primary-2 {
    background-color: #4e73df;
    background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
    background-size: cover;
}

.bg-gradient-primary-3 {
  background-color: #0f1f7caa;
  background-image: linear-gradient(180deg, #0f1f7caa 10%, #091349aa 100%);
  background-size: cover;
}
.bg-gradient-primary-4 {
  background: rgb(9,19,73);
  background: linear-gradient(0deg, rgba(15,32,124,1) 0%, rgba(15,32,124,1) 50%, rgba(34,74,190,1) 100%);
  /* box-shadow: 0 0 5rem rgba(78, 115, 223,0.75); */
  background-size: cover;
}

.bg-gradient-secondary {
    background-color: #858796;
    background-image: linear-gradient(180deg, #858796 10%, #60616f 100%);
    background-size: cover;
}

.bg-gradient-success {
    background-color: #198754;
    background-image: linear-gradient(180deg, rgb(0, 83, 44) 10%, #00361d 100%);
    background-size: cover;
}

.bg-gradient-info {
    background-color: #36b9cc;
    background-image: linear-gradient(180deg, #36b9cc 10%, #258391 100%);
    background-size: cover;
}

.bg-gradient-warning {
    background-color: #f6c23e;
    background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
    background-size: cover;
}

.bg-gradient-warning-2 {
    background-color: #ffb901;
    background-image: linear-gradient(180deg, #ffb901 10%, #9c7000 100%);
    background-size: cover;
}

.bg-gradient-danger {
    background-color: #e74a3b;
    background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%);
    background-size: cover;
}

.bg-gradient-light {
    background-color: #f8f9fc;
    background-image: linear-gradient(180deg, #f8f9fc 10%, #c2cbe5 100%);
    background-size: cover;
}

.bg-gradient-dark {
    background-color: #42424b;
    background-image: linear-gradient(180deg, #42424b 10%, #303138 100%);
    background-size: cover;
}



.text-gray-100 {
    color: #f8f9fc !important;
}

.text-gray-200 {
    color: #eaecf4 !important;
}

.text-gray-300 {
    color: #dddfeb !important;
}

.text-gray-400 {
    color: #d1d3e2 !important;
}

.text-gray-500 {
    color: #b7b9cc !important;
}

.text-gray-600 {
    color: #858796 !important;
}

.text-gray-700 {
    color: #6e707e !important;
}

.text-gray-800 {
    color: #5a5c69 !important;
}

.text-gray-900 {
    color: #3a3b45 !important;
}



.btn-primary-2 {
  background-color: #0b59a1 !important;
  color: white !important;
  border: none;
  transition: 0.1s ease-in;
}
.btn-primary-2:hover {
  background-color: #094680 !important;
  color: white !important;
  border: none;
}
.btn-primary-2:focus {
  background-color: #0b59a1 !important;
  color: white !important;
  border: none;
}
.btn-primary-2:active {
  background-color: #094680 !important;
  color: white !important;
  border: none;
}

.btn-primary-3 {
  background-color: #172eb4 !important;
  color: white !important;
  border: none;
  transition: 0.1s ease-in;
  /* transform: scale(1); */
}
.btn-primary-3:hover {
  background-color: #0f207c !important;
  color: white !important;
  border: none;
  /* transform: scale(1.025); */
}
.btn-primary-3:focus {
  background-color: #172eb4 !important;
  color: white !important;
  border: none;
  /* transform: scale(1); */
}
.btn-primary-3:active {
  background-color: #0f207c !important;
  color: white !important;
  border: none;
  /* transform: scale(1.025); */
}

.btn-primary-4 {
  background-color: #0f207c !important;
  color: white !important;
  border: none;
  transition: 0.1s ease-in;
  /* transform: scale(1); */
}
.btn-primary-4:hover {
  background-color: #162ca8 !important;
  color: white !important;
  border: none;
  /* transform: scale(1.025); */
}
.btn-primary-4:focus {
  background-color: #0f207c !important;
  color: white !important;
  border: none;
  /* transform: scale(1); */
}
.btn-primary-4:active {
  background-color: #162ca8 !important;
  color: white !important;
  border: none;
  /* transform: scale(1.025); */
}

.btn-warning-2 {
  background-color: #ffb901 !important;
  color: black !important;
  border: none;
  transition: 0.1s ease-in;
}
.btn-warning-2:hover {
  background-color: #dd9e00 !important;
  color: black !important;
  border: none;
}
.btn-warning-2:focus {
  background-color: #ffb901 !important;
  color: black !important;
  border: none;
}
.btn-warning-2:active {
  background-color: #dd9e00 !important;
  color: black !important;
  border: none;
}

.btn-success-2 {
  background-color: #008069 !important;
  color: white !important;
  border: none;
  transition: 0.1s ease-in;
}
.btn-success-2:hover {
  background-color: #00614f !important;
  color: white !important;
  border: none;
}
.btn-success-2:focus {
  background-color: #008069 !important;
  color: white !important;
  border: none;
}
.btn-success-2:active {
  background-color: #00614f !important;
  color: white !important;
  border: none;
}

.btn-dark {
  transition: 0.1s ease-in-out;
}



.btn-primary {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
  }
  
  .btn-primary:hover {
    color: #fff;
    background-color: #2e59d9;
    border-color: #2653d4;
  }
  
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #2e59d9;
    border-color: #2653d4;
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
  }
  
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2653d4;
    border-color: #244ec9;
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
  }
  
  .btn-secondary {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
  }
  
  .btn-secondary:hover {
    color: #fff;
    background-color: #717384;
    border-color: #6b6d7d;
  }
  
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #717384;
    border-color: #6b6d7d;
    box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
  }
  
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6b6d7d;
    border-color: #656776;
  }
  
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(151, 153, 166, 0.5);
  }

  
  .btn-info {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
  }
  
  .btn-info:hover {
    color: #fff;
    background-color: #2c9faf;
    border-color: #2a96a5;
  }
  
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #2c9faf;
    border-color: #2a96a5;
    box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
  }
  
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
  }
  
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #2a96a5;
    border-color: #278c9b;
  }
  
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(84, 196, 212, 0.5);
  }
  
  .btn-warning {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
  }
  
  .btn-warning:hover {
    color: #fff;
    background-color: #f4b619;
    border-color: #f4b30d;
  }
  
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #f4b619;
    border-color: #f4b30d;
    box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
  }
  
  .btn-warning.disabled, .btn-warning:disabled {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f4b30d;
    border-color: #e9aa0b;
  }
  
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 203, 91, 0.5);
  }
  
  .btn-danger {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
  }
  
  .btn-danger:hover {
    color: #fff;
    background-color: #e02d1b;
    border-color: #d52a1a;
  }
  
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #e02d1b;
    border-color: #d52a1a;
    box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d52a1a;
    border-color: #ca2819;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 101, 88, 0.5);
  }
  
  .btn-light {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
  }
  
  .btn-light:hover {
    color: #3a3b45;
    background-color: #dde2f1;
    border-color: #d4daed;
  }
  
  .btn-light:focus, .btn-light.focus {
    color: #3a3b45;
    background-color: #dde2f1;
    border-color: #d4daed;
    box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
  }
  
  .btn-light.disabled, .btn-light:disabled {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
  }
  
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #3a3b45;
    background-color: #d4daed;
    border-color: #cbd3e9;
  }
  
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 221, 225, 0.5);
  }
  
  .btn-dark {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
  }
  
  .btn-dark:hover {
    color: #fff;
    background-color: #484a54;
    border-color: #42444e;
  }
  
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #484a54;
    border-color: #42444e;
    box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
  }
  
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #42444e;
    border-color: #3d3e47;
  }
  
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(115, 116, 128, 0.5);
  }
  
  .btn-outline-primary {
    color: #4e73df;
    border-color: #4e73df;
  }
  
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
  }
  
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
  }
  
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #4e73df;
    background-color: transparent;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
  }
  
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.5);
  }
  
  .btn-outline-secondary {
    color: #858796;
    border-color: #858796;
  }
  
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
  }
  
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
  }
  
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #858796;
    background-color: transparent;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
  }
  
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(133, 135, 150, 0.5);
  }
  
  .btn-outline-success {
    color: #1cc88a;
    border-color: #1cc88a;
  }
  
  .btn-outline-success:hover {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
  }
  
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
  }
  
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #1cc88a;
    background-color: transparent;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #1cc88a;
    border-color: #1cc88a;
  }
  
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(28, 200, 138, 0.5);
  }
  
  .btn-outline-info {
    color: #36b9cc;
    border-color: #36b9cc;
  }
  
  .btn-outline-info:hover {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
  }
  
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
  }
  
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #36b9cc;
    background-color: transparent;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #36b9cc;
    border-color: #36b9cc;
  }
  
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 185, 204, 0.5);
  }
  
  .btn-outline-warning {
    color: #f6c23e;
    border-color: #f6c23e;
  }
  
  .btn-outline-warning:hover {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
  }
  
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
  }
  
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f6c23e;
    background-color: transparent;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #fff;
    background-color: #f6c23e;
    border-color: #f6c23e;
  }
  
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 194, 62, 0.5);
  }
  
  .btn-outline-danger {
    color: #e74a3b;
    border-color: #e74a3b;
  }
  
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
  }
  
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
  }
  
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #e74a3b;
    background-color: transparent;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #e74a3b;
    border-color: #e74a3b;
  }
  
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(231, 74, 59, 0.5);
  }
  
  .btn-outline-light {
    color: #f8f9fc;
    border-color: #f8f9fc;
  }
  
  .btn-outline-light:hover {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
  }
  
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
  }
  
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fc;
    background-color: transparent;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #3a3b45;
    background-color: #f8f9fc;
    border-color: #f8f9fc;
  }
  
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 252, 0.5);
  }
  
  .btn-outline-dark {
    color: #5a5c69;
    border-color: #5a5c69;
  }
  
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
  }
  
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
  }
  
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #5a5c69;
    background-color: transparent;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #5a5c69;
    border-color: #5a5c69;
  }
  
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(90, 92, 105, 0.5);
  }



  .btn-gradient-info {
    background-color: rgb(100, 255, 255) !important;
    background-image: linear-gradient(180deg, rgb(25, 175, 175) 10%, rgb(0, 75, 175) 100%) !important;
    background-size: cover;
    color: white !important;
    border: none;
}
.btn-gradient-info:hover {
    background-color: rgb(100, 255, 255) !important;
    background-image: linear-gradient(180deg, rgb(0, 150, 150) 10%, rgb(0, 50, 150) 100%) !important;
    background-size: cover;
    color: rgb(225, 225, 225) !important;
    border: none;
}
.btn-gradient-info:focus {
    background-color: rgb(100, 255, 255) !important;
    background-image: linear-gradient(180deg, rgb(25, 175, 175) 10%, rgb(0, 75, 175) 100%) !important;
    background-size: cover;
    color: white !important;
    border: none;
}
.btn-gradient-info:active {
    background-color: rgb(100, 255, 255) !important;
    background-image: linear-gradient(180deg, rgb(0, 150, 150) 10%, rgb(0, 50, 150) 100%) !important;
    background-size: cover;
    color: rgb(225, 225, 225) !important;
    border: none;
}
.btn-gradient-primary {
    background-color: #4e73df !important;
    background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%) !important;
    background-size: cover;
    color: white !important;
    border: none;
}
.btn-gradient-primary:hover {
    background-color: #4e73df !important;
    background-image: linear-gradient(180deg, #4262c0 10%, #1d3da0 100%) !important;
    background-size: cover;
    color: rgb(225, 225, 225) !important;
    border: none;
}
.btn-gradient-primary:focus {
    background-color: #4e73df !important;
    background-image: linear-gradient(180deg, #4262c0 10%, #1d3da0 100%) !important;
    background-size: cover;
    color: rgb(225, 225, 225) !important;
    border: none;
}
.btn-gradient-danger {
    background-color: #e74a3b !important;
    background-image: linear-gradient(180deg, #e74a3b 10%, #be2617 100%) !important;
    background-size: cover;
    color: white !important;
    border: none;
}
.btn-gradient-danger:hover {
    background-color: #e74a3b !important;
    background-image: linear-gradient(180deg, #be3d32 10%, #b92618 100%) !important;
    background-size: cover;
    color: rgb(200, 200, 200) !important;
    border: none;
}
.btn-gradient-danger:focus {
    background-color: #e74a3b !important;
    background-image: linear-gradient(180deg, #be3d32 10%, #b92618 100%) !important;
    background-size: cover;
    color: rgb(200, 200, 200) !important;
    border: none;
}
.btn-gradient-success {
    background-color: #15a570;
    background-image: linear-gradient(180deg, #15a570 10%, #10704d 100%);
    background-size: cover;
    color: white !important;
    border: none;
}
.btn-gradient-success:hover {
    background-color: #15a570;
    background-image: linear-gradient(180deg, #138f61 10%, #0e5f41 100%);
    background-size: cover;
    color: rgb(225, 225, 225) !important;
    border: none;
}
.btn-gradient-success:focus {
    background-color: #15a570;
    background-image: linear-gradient(180deg, #138f61 10%, #0e5f41 100%);
    background-size: cover;
    color: rgb(225, 225, 225) !important;
    border: none;
}
.btn-gradient-warning {
    background-color: #f6c23e;
    background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
    background-size: cover;
    color: black !important;
    border: none;
}
.btn-gradient-warning:hover {
    background-color: #f6c23e;
    background-image: linear-gradient(180deg, #daab37 10%, #c28d09 100%);
    background-size: cover;
    color: rgb(25, 25, 25) !important;
    border: none;
}
.btn-gradient-warning:focus {
    background-color: #f6c23e;
    background-image: linear-gradient(180deg, #f6c23e 10%, #dda20a 100%);
    background-size: cover;
    color: rgb(25, 25, 25) !important;
    border: none;
}



  .error {
    color: #5a5c69;
    font-size: 7rem;
    position: relative;
    line-height: 1;
    width: 12.5rem;
  }
  
  @keyframes noise-anim {
    0% {
      clip: rect(49px, 9999px, 40px, 0);
    }
    5% {
      clip: rect(75px, 9999px, 72px, 0);
    }
    10% {
      clip: rect(97px, 9999px, 93px, 0);
    }
    15% {
      clip: rect(15px, 9999px, 9px, 0);
    }
    20% {
      clip: rect(14px, 9999px, 92px, 0);
    }
    25% {
      clip: rect(18px, 9999px, 94px, 0);
    }
    30% {
      clip: rect(17px, 9999px, 20px, 0);
    }
    35% {
      clip: rect(71px, 9999px, 59px, 0);
    }
    40% {
      clip: rect(42px, 9999px, 84px, 0);
    }
    45% {
      clip: rect(56px, 9999px, 25px, 0);
    }
    50% {
      clip: rect(46px, 9999px, 14px, 0);
    }
    55% {
      clip: rect(47px, 9999px, 1px, 0);
    }
    60% {
      clip: rect(64px, 9999px, 58px, 0);
    }
    65% {
      clip: rect(89px, 9999px, 92px, 0);
    }
    70% {
      clip: rect(56px, 9999px, 39px, 0);
    }
    75% {
      clip: rect(80px, 9999px, 71px, 0);
    }
    80% {
      clip: rect(8px, 9999px, 13px, 0);
    }
    85% {
      clip: rect(66px, 9999px, 68px, 0);
    }
    90% {
      clip: rect(68px, 9999px, 4px, 0);
    }
    95% {
      clip: rect(56px, 9999px, 14px, 0);
    }
    100% {
      clip: rect(28px, 9999px, 53px, 0);
    }
  }
  
  .error:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 #e74a3b;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 2s infinite linear alternate-reverse;
  }
  
  @keyframes noise-anim-2 {
    0% {
      clip: rect(16px, 9999px, 10px, 0);
    }
    5% {
      clip: rect(22px, 9999px, 29px, 0);
    }
    10% {
      clip: rect(6px, 9999px, 68px, 0);
    }
    15% {
      clip: rect(85px, 9999px, 95px, 0);
    }
    20% {
      clip: rect(65px, 9999px, 91px, 0);
    }
    25% {
      clip: rect(93px, 9999px, 68px, 0);
    }
    30% {
      clip: rect(10px, 9999px, 27px, 0);
    }
    35% {
      clip: rect(37px, 9999px, 25px, 0);
    }
    40% {
      clip: rect(12px, 9999px, 23px, 0);
    }
    45% {
      clip: rect(40px, 9999px, 18px, 0);
    }
    50% {
      clip: rect(19px, 9999px, 71px, 0);
    }
    55% {
      clip: rect(2px, 9999px, 35px, 0);
    }
    60% {
      clip: rect(16px, 9999px, 69px, 0);
    }
    65% {
      clip: rect(8px, 9999px, 65px, 0);
    }
    70% {
      clip: rect(30px, 9999px, 57px, 0);
    }
    75% {
      clip: rect(14px, 9999px, 4px, 0);
    }
    80% {
      clip: rect(39px, 9999px, 30px, 0);
    }
    85% {
      clip: rect(22px, 9999px, 35px, 0);
    }
    90% {
      clip: rect(58px, 9999px, 71px, 0);
    }
    95% {
      clip: rect(34px, 9999px, 90px, 0);
    }
    100% {
      clip: rect(67px, 9999px, 68px, 0);
    }
  }
  
  .error:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 #4e73df;
    top: 0;
    color: #5a5c69;
    background: #f8f9fc;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
  }


.loadings {
  width: 150px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ring {
  width: 100px;
  height: 100px;
  border: 0px solid rgb(0, 0, 0, 0);
  border-radius: 50%;
  position: absolute;
}

.ring:nth-child(1) {
  border-bottom-width: 6px;
  border-color: #0f207c;
  animation: loading1 0.5s linear infinite;
}
.ring:nth-child(2) {
  border-right-width: 6px;
  border-color: #087ab6;
  animation: loading2 0.5s linear infinite;
}
.ring:nth-child(3) {
  border-top-width: 6px;
  border-color: #ffb901;
  animation: loading3 0.5s linear infinite;
}



.authorization {
  width: 100px;
  height: 100px;
  border: 0px solid rgb(0, 0, 0, 0);
  border-radius: 50%;
  position: absolute;
}

.authorization:nth-child(1) {
  border-bottom-width: 6px;
  border-color: #ffffff;
  animation: loading1 0.5s linear infinite;
}
.authorization:nth-child(2) {
  border-right-width: 6px;
  border-color: #087ab6;
  animation: loading2 0.5s linear infinite;
}
.authorization:nth-child(3) {
  border-top-width: 6px;
  border-color: #ffb901;
  animation: loading3 0.5s linear infinite;
}

@keyframes loading1 {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}
@keyframes loading2 {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}
@keyframes loading3 {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
